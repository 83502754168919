.o-header{
    background: $bg-white;
    box-shadow: $box-shadow-primary;
    position: relative;
    z-index: 4;
}


.o-header.-cart{
    & .m-navToggler, & .m-navSearchToggler{
        display: none;
    }
    .m-navLogo__link{
        justify-content: center;
    }
}