.o-productDetailVariants{
    background: $bg-white;
    padding: 4rem 0;
}
.o-productDetailVariants__title{
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 4rem;
    text-align: center;
}
.o-productDetailVariants__heading{
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid $border-grey;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.o-productDetailVariants__headinsSingle{
    color: $text-medium;
    font-size: 1.4rem;
    width: 23%;

    &:first-child{
        width: auto;
        flex-grow: 1;
    }
    &:nth-child(3){
        width: 15%;
    }
    &:last-child{
        width: 14%;
    }
}

.o-productDetailVariants__single{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;
}
.o-productDetailVariants__imgWrap{
    height: 80px;
    width: 80px;
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.o-productDetailVariants__img{
    max-height: 100%;
}
.o-productDetailVariants__text{
    color: $text-primary;
    font-size: 1.4rem;
    width: 23%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    &:nth-child(2){
        flex-grow: 1;
    }
    &:last-child{
        width: 14%;
    }

    &.-stock{
        color: $text-green;
    }
    &.-price{
        color: $text-red;
        font-weight: 600;
        width: 15%;
    }
    &.-link{
        font-weight: 600;
        justify-content: flex-end;
    }
}
.o-productDetailVariants__icon{
    height: 8px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    width: 8px;
}
@media screen and (max-width:991px){
    .o-productDetailVariants{
        display: none;
    }
}