.m-tabsNav{
    background: $bg-white;

    &.-paddingBottom{
        padding-bottom: 20px;
    }
}
.m-tabsNav__nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
    position: relative;
    z-index: 1;

    &::after{
        content: "";
        height: 1px;
        width: 100%;
        background: $bg-grey;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
    }
}
.m-tabsNav__link{
    background: transparent;
    border: 0;
    min-height: 5.4rem;
    padding: 0 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include f-16-semibold-regular;
    border-bottom: 1px solid transparent;

    &:hover, &:active, &:focus{
        text-decoration: none;
        background: transparent;
        color: $text-primary;
        border-bottom: 1px solid $border-primary;
    }
}
.m-tabsNav__link.active{
    background: transparent;
    color: $text-brand;
    border-bottom: 1px solid $border-brand;
}





.m-tabsContent{
    background: $bg-white;
    padding: 2rem 0;

    & .container{
        padding: 10px;
    }
}
.m-tabsContent__btnWrap{
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 3rem;
}
.m-tabsContent__collapseToggle{
    padding: 15px 20px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 2px solid $border-white;
    background: $bg-light;
    @include f-14-primary-semibold;
}
.m-tabsContent__collapse{
    
}
@media screen and (min-width:501px){
    .m-tabsContent__collapse{
        display: block !important;
    }
}

@media screen and (max-width:991px){
    .m-tabsContent{
        padding: 10px 20px;
        & .container{
            padding: 0;
        }
    }
}
@media screen and (max-width: 768px){
    .m-tabsContent{
        padding: 10px 0px;

        & .container{
            padding: 0;
        }
    }
}
@media screen and (max-width:500px){
    .m-tabsNav__nav{
        display: none;
    }
    .m-tabsContent{
        padding: 0;
        margin: 0 10px;
    }
    .m-tabsContent__collapseToggle{
        display: flex;
    }
    .m-tabsContent__pane{
        display: block !important;
        opacity: 1 !important;
    }
    .m-tabsContent__collapse .container{
        padding: 10px 0px;
        border-top: 2px solid $border-white;
    }
}

/* IE 10, 11 CSS */ 
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .m-tabsNav__link{
        height: 5.4rem;
    }
}